
.purple {
    color: #4B45FF;
    cursor: pointer;
}
.upload-form {
    box-sizing: border-box;
    height: 100%;
    .form-item {
        box-sizing: border-box;
        display: flex;
        width: 660px;
        height: 96px;
        background-color: #0C0E3F;
        border-radius: 4px;
    }
    .hint {
        margin-top: 10px;
        color: #5F5E80;
    }
    .video-content-form {
        flex-direction: column;
        justify-content: center;
        padding: 0 40px;
        .video-info {
            display: flex;
            justify-content: space-between;
            .info {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                .replace {
                    margin-left: 17px;
                }
            }
        }
    }
    .set-cover {
        padding: 0 50px 0 20px;
        display: flex;
        align-items: center;
        .cover {
            width: 60px;
            height: 60px;
            border-radius: 4px;
            overflow: hidden;
            .video {
                width: 100%;
                height: 100%;
                overflow: hidden;
            }
            .default-img {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                background-color: #1D2067;
                .icon {
                    font-size: 37px;
                    color: #5559B1;
                }
            }
        }
        .hint {
            box-sizing: border-box;
            flex: 1;
            margin: 0 15px;
            color: #fff;
            opacity: .5;
            overflow: hidden;
        }
    }
    .textarea {
        box-sizing: border-box;
        width: 660px;
        height: 200px;
        padding: 10px;
        margin-bottom: 10px;
        background-color: #0c0e3f;
        outline: none;
        overflow: hidden;
        word-break: break-all;
        /deep/ .el-textarea__inner {
            height: 100%;
            color: #fff;
            background-color: #0c0e3f;
            border: none;
        }
    }
}

